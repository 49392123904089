/* eslint-disable react/jsx-wrap-multilines */
import React from 'react'

import { Layout } from '@leshen/gatsby-theme-contentful'
import { graphql } from 'gatsby'

import { Main } from '@leshen/ui'

import {
  mapRichTextHero,
  mapSections,
} from '@leshen/gatsby-theme-contentful/utils/determiners'

import ZipPackageSection from '../components/ZipPackageSection'

const DynamicZipPackages = ({ data }) => {
  const {
    contentfulPage: { sections, hero, spanish },
  } = data

  const heroOptions = {
    mapper: mapRichTextHero,
    isImageCritical: true,
    spanish,
  }

  const sectionOptions = {
    spanish,
  }

  const ctaBar =
    sections[0]?.__typename === 'ContentfulCtaBar' ? sections[0] : null // eslint-disable-line no-underscore-dangle

  const possiblyModifiedSections = ctaBar ? sections.slice(1) : sections

  return (
    <Layout
      data={data}
      main={
        <Main>
          {/* Pass the hero to Leshens map so it behaves like normal when coming from Contentful */}
          {mapSections(hero, heroOptions)}
          {ctaBar && mapSections(ctaBar, sectionOptions)}
          <div id="loadingScrollTarget" />

          {/* AvailablePlans will show the package cards based on what is available from the Availability form getting used from the Hero. */}
          <ZipPackageSection formData={data.allContentfulForm.edges[0].node} />

          {/* Render the rest of the sections from Contentful like normal */}
          {possiblyModifiedSections &&
            possiblyModifiedSections.map((section) =>
              mapSections(section, sectionOptions)
            )}
        </Main>
      }
    />
  )
}

export default DynamicZipPackages

export const query = graphql`
  query DynamicZipPackagesQuery($id: String!) {
    contentfulPage(id: { eq: $id }) {
      ...LeshenPage
    }
    site {
      ...LeshenMetadata
    }
    allContentfulForm(
      filter: { formGuid: { eq: "433f226d-90a3-49a8-9e2f-25ab1fa42c62" } }
    ) {
      edges {
        node {
          identifier
          heading
          subheading

          formDisclaimer {
            __typename
            ...LeshenDisclaimer
          }

          emily {
            emily_guid
            name
            submit {
              action
              buttonText
            }
            visibleFieldCount
            formFields {
              element
              label
              name
              type
              required
              value
              options {
                label
                name
                value
              }
            }
          }
          emilyMultiStep {
            emily_guid
            name
            submit {
              action
              buttonText
            }
            steps {
              formFields {
                value
                type
                required
                options {
                  name
                  label
                  value
                }
                name
                label
                element
              }
            }
          }
        }
      }
    }
  }
`
